import { useCallback } from "react";
import { PaymentMode, ShipmentService } from "../interfaces";
import { RangeDeclareDto } from "../interfaces/Dtos";
import { useAppSelector } from "../store/hooks";
import { formatFloat } from "./format";

export const handleNumberChange = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  if (event.target.value.length > 1) {
    event.target.value = event.target.value.replace(/^0+/, "");
  }
  const setValue = (value: string) => {
    event.target.value = value;
  };

  if (!formatFloat(event.target.value, setValue)) {
    return false;
  }
  return true;
};

export const useVerifyDeclaredValue = (totalChargedWeight: number) => {
  const rangeMinDeclareList = useAppSelector(
    (state) => state.inmutable.rangeDeclareList
  );

  const minDeclaredValue = (weight: number, rangeList: RangeDeclareDto[]) => {
    return (
      rangeList.find((r) => r.minWeight < weight && weight <= r.maxWeight)
        ?.minDeclareValue ?? 0
    );
  };

  const verifyDeclaredValue = useCallback(
    (
      value: string,
      service: ShipmentService,
      paymentMode: PaymentMode,
    ) => {
      if (
        service !== ShipmentService.STANDARD ||
        (paymentMode !== PaymentMode.COD && paymentMode !== PaymentMode.CONTADO)
      ) {
        return {
          didError: false,
          error: "",
        };
      }

      const minVal = minDeclaredValue(totalChargedWeight, rangeMinDeclareList);

      if (parseFloat(value ? value : "0") < minVal) {
        return {
          didError: true,
          error: `El valor declarado no puede ser menor a ${minVal.toFixed(
            2
          )}$`,
        };
      } else {
        return {
          didError: false,
          error: "",
        };
      }
    },
    [rangeMinDeclareList, totalChargedWeight]
  );

  return verifyDeclaredValue;
};
