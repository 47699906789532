import api from "./api";
import { alertService } from "./alertService";

const URLPrinter = process.env.REACT_APP_API_PRINTER_HOST; // For printer local --port 8000
const baseURL = process.env.REACT_APP_API_HOST;

// Method to verify if the printer is avaliable
export const IsAvaliablePrint = async (): Promise<boolean> => {
  let result: boolean;
  try {
    const response = await api.get(`${URLPrinter}`);
    if (response.status === 200) {
      result = true;
    } else {
      result = false;
    }
  } catch (error) {
    alertService.error(
      "No se pudo comunicar con la impresora, verifique que el programa se este ejecutando."
    );
    result = false;
  }
  return result;
};

async function getLabels(
  uri: string,
  ShipmentNumber: number,
  ServiceID: number,
  BuCodeSource: string,
  PiecesNumber?: number[]
) {
  let respose: any = [];
  await api
    .post(uri, {
      ShipmentNumber,
      ServiceID,
      BuCodeSource,
      PiecesNumber,
    })
    .then((res) => {
      respose = res.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return respose;
}

async function getShipmentLabels(
  ShipmentNumber: number,
  ServiceID: number,
  BuCodeSource: string
) {
  return await getLabels(
    `${baseURL}/v1/Shipment/GetShipmentLabel`,
    ShipmentNumber,
    ServiceID,
    BuCodeSource,
    undefined
  );
}

async function getPieceLabels(
  ShipmentNumber: number,
  ServiceID: number,
  BuCodeSource: string,
  PiecesNumber: number[]
) {
  return await getLabels(
    `${baseURL}/v1/Shipment/GetShipmentLabelV2`,
    ShipmentNumber,
    ServiceID,
    BuCodeSource,
    PiecesNumber
  );
}

const PrintLabel = async (
  stringLabel: string,
  shipmentNumber: number
): Promise<boolean> => {
  let result: boolean;
  try {
    if (stringLabel.length === 0) {
      alertService.error(
        "Guía: " + shipmentNumber,
        "No se pudo obtener ninguna etiqueta de la guía especificada."
      );
      return false;
    }
    let AvaliablePrint = await IsAvaliablePrint();
    if (!AvaliablePrint) {
      return false;
    }
    result = await new Promise(async (resolve, rejected) => {
      await api
        .post(`${URLPrinter}/PrintMultipleShipment`, {
          StringLabel: stringLabel,
        })
        .then((res) => {
          resolve(true);
        })
        .catch((error) => {
          rejected(false);
        });
    });
  } catch (error) {
    result = false;
  }
  return result;
};

// Method to print the labels of the shipment using the external printer service
export const PrintShipmentLabel = async (
  ShipmentNumber: number,
  ServiceID: number,
  BuCodeSource: string
): Promise<boolean> => {
  const stringLabel = await getShipmentLabels(
    ShipmentNumber,
    ServiceID,
    BuCodeSource
  );

  return await PrintLabel(stringLabel, ShipmentNumber);
};

// Method to print the labels of the pieces using the external printer service
export const PrintPieceLabel = async (
  ShipmentNumber: number,
  ServiceID: number,
  BuCodeSource: string,
  PiecesNumber: number[]
): Promise<boolean> => {
  const stringLabel = await getPieceLabels(
    ShipmentNumber,
    ServiceID,
    BuCodeSource,
    PiecesNumber
  );

  return await PrintLabel(stringLabel, ShipmentNumber);
};
