export interface ItemInterface {
  id: string;
  code: string;
  name: string;
  order: number;
  mandatory: boolean;
  isItemBase: boolean;
  isItemShipment?: boolean;
  rate: {
    value: number;
    iva: number;
    ipostel: number;
    isPercentage: boolean;
    distance: number;
    tierCode: string;
    tierName: string;
  };
}

export const discountItem: ItemInterface = {
  id: "31",
  code: "0310",
  name: "Bonificación a Cliente",
  order: 0,
  isItemBase: false,
  mandatory: false,
  rate: {
    value: 0,
    isPercentage: false,
    iva: 0,
    ipostel: 0,
    distance: 0,
    tierCode: "",
    tierName: "",
  },
};

export interface GetShipmentItemsRequestDTO {
  CountryID: number;
  Weight: number;
  DeclaratedValue: number;
  Distance: number;
  CurrencyID: number;
  ApplicationID: number;
  ShipperAccountID?: string;
  ConsigneeAccountID?: string;
  AccountBillToID?: string;
  AccountSiteID?: number;
  ServiceID?: number;
  PaymentModeID?: number;
  DeliveryTypeID?: number;
  SRM?: boolean;
  TierID?: number;
  MeasureUnitTypeID?: number;
  ShippingMethodID?: number;
  PackageTypeID?: number;
  CategoryID?: number;
  SalesDate?: string;
  BUCodeSource?: string;
  BUCodeConsignee?: string;
}
