import PriceCalculator from "../modules/PriceCalculator/PriceCalculator";
import ShipmentCreate from "../modules/Shipments/ShipmentCreate";
import ShipmentView from "../modules/ShipmentView";

interface AppRoute {
  index?: boolean;
  path?: string;
  element: React.ReactNode;
  /**
   * If the route needs authentication
   */
  auth?: boolean;
  exact?: boolean;
  rolesRequired?: string[];
}

const AppRoutes: AppRoute[] = [
  {
    path: "/shipment/:shipmentNumber",
    element: <ShipmentView />,
    auth: false,
  },
  { path: "/shipment-create", element: <ShipmentCreate />, auth: true },
  { path: "/price-calculator", element: <PriceCalculator />, auth: false }
];

export default AppRoutes;
