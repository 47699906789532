import { FC, useContext, useMemo } from "react";
import Modal from "../Modal";
import LoadingIcon from "../LoadingIcon";
import {
  PaymentInterface,
  PaymentMethodEnum,
  PaymentStatusEnum,
} from "../../interfaces";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import { currencyExchangeText, useCurrencyExchanges } from "../../utils";
import {
  XMarkIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { PaymentCallbackContext } from "../../contexts/PaymentCallbackContext";

export enum PaymentConfirmModalStatus {
  CONFIRM,
  PROCESSING,
  SUCCESS,
  ERROR,
  CANCEL,
}

interface PaymentConfirmModalProps {
  payment: PaymentInterface;
  status: PaymentConfirmModalStatus;
  remaining: number;
  currentIGTF: number;
  openModal: boolean;
  isRetention: boolean;
  error?: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentConfirmationModalClose?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  pay: () => void;
  onError?: () => void;
  onRegisterMerchantPayment?: () => void;
}

const PaymentConfirmModal: FC<PaymentConfirmModalProps> = ({
  payment,
  status,
  isRetention,
  remaining,
  currentIGTF,
  error,
  openModal,
  setOpenModal,
  setPaymentConfirmationModalClose,
  pay,
  onError = () => {},
  onRegisterMerchantPayment = () => {},
}) => {
  const exchanges = useCurrencyExchanges();
  const paymentCallbackContext = useContext(PaymentCallbackContext);

  const amountToTax = useMemo(() => {
    if (
      !(
        payment.paymentMethod.paymentMethodID === PaymentMethodEnum.EFECTIVO &&
        payment.paymentMethod.currencyID === 2
      )
    )
      return 0;

    // The amount to which the taxes will be applied will be equal to the
    // minimum between the amount to be paid and the rest less the previous
    // calculation
    return Math.max(0, Math.min(payment.amount, remaining - currentIGTF));
  }, [payment, remaining, currentIGTF]);

  return (
    <Modal openModal={openModal} setOpenModal={() => {}}>
      <div className="flex flex-col gap-4">
        {status === PaymentConfirmModalStatus.CONFIRM && (
          <>
            {/* Header */}
            <div className="flex flex-row justify-between mb-4">
              <h2 className="text-xl font-bold">Confirmar pago</h2>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => setOpenModal(false)}
                >
                  <span className="absolute -inset-2.5" />
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            {/* Body */}
            <div className="flex flex-col">
              <div className="flex flex-row justify-between gap-16">
                <p className="font-semibold">Método de pago: </p>
                <p>{payment.paymentMethod.paymentMethodName}</p>
              </div>
              <hr className="my-2" />

              {amountToTax > 0 && (
                <div className="flex flex-row justify-between gap-16">
                  <p className="font-semibold">IGTF: </p>
                  <div className="flex flex-col items-end">
                    <p>
                      {currencyExchangeText(
                        amountToTax * 0.03,
                        exchanges,
                        "USD"
                      )}
                    </p>
                    <p className="text-xs text-gray-400 ">
                      {currencyExchangeText(
                        amountToTax * 0.03,
                        exchanges,
                        "BS"
                      )}
                    </p>
                  </div>
                </div>
              )}

              <div className="flex flex-row justify-end gap-16 mt-6">
                <div className="flex flex-col items-end">
                  <p className="text-sm text-gray-600">
                    {!isRetention ? "Monto a abonar:" : "Monto a retener"}{" "}
                  </p>
                  <p>
                    {currencyExchangeText(payment.amount, exchanges, "USD")}
                  </p>
                  <p className="text-xs text-gray-400 ">
                    {currencyExchangeText(payment.amount, exchanges, "BS")}
                  </p>
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex flex-row justify-between gap-4">
              <SecondaryButton
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                CANCELAR
              </SecondaryButton>
              <PrimaryButton className="px-4" onClick={() => pay()}>
                CONFIRMAR
              </PrimaryButton>
            </div>
          </>
        )}

        {status === PaymentConfirmModalStatus.PROCESSING && (
          <div className="flex flex-col items-center justify-center">
            <LoadingIcon size="4rem" />
            <p className="text-gray-500">Procesando pago...</p>
          </div>
        )}

        {status === PaymentConfirmModalStatus.ERROR && (
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center w-full">
              <XCircleIcon className="text-red-600 w-32 h-32" />
            </div>
            <p className="mt-2 text-2xl font-medium text-gray-900">
              Error al procesar el pago
            </p>
            <p className="mt-2 text-gray-500">{error}</p>
            <div className="mt-4 flex flex-row justify-center gap-4">
              <PrimaryButton
                className="px-4"
                onClick={async () => {
                  setOpenModal(false);
                  onError();
                }}
              >
                Aceptar
              </PrimaryButton>
            </div>

            {(payment.paymentMethod.paymentMethodID ===
              PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO ||
              payment.paymentMethod.paymentMethodID ===
                PaymentMethodEnum.PAGO_MERCHANT_P2C ||
              payment.paymentMethod.paymentMethodID ===
                PaymentMethodEnum.PAGO_MERCHANT_TARJETAS_DE_CREDITO) && (
              <p className="pt-6 text-xs">
                Si el pago por el punto fue exitoso haga{" "}
                <LinkText
                  text="click aquí"
                  onClick={onRegisterMerchantPayment}
                />{" "}
                para registrarlo
              </p>
            )}
          </div>
        )}

        {status === PaymentConfirmModalStatus.SUCCESS && (
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center justify-center w-full">
              <CheckCircleIcon className="text-green-600 w-32 h-32" />
            </div>
            <p className="mt-2 text-2xl font-medium text-gray-900">
              {payment.paymentMethod.paymentMethodID ===
                PaymentMethodEnum.REINTEGRO &&
              payment.status === PaymentStatusEnum.PENDING
                ? "Solicitud de reintegro creada exitosamente"
                : !isRetention
                ? "Pago procesado exitosamente"
                : "Retención guardada exitosamente"}
            </p>
            <div className="mt-4 flex flex-row justify-center gap-4">
              <PrimaryButton
                className="px-4"
                onClick={() => {
                  if (setPaymentConfirmationModalClose) {
                    setPaymentConfirmationModalClose(true);
                  }
                  setOpenModal(false);
                  paymentCallbackContext?.onFinishPayment();
                }}
              >
                Aceptar
              </PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PaymentConfirmModal;
