import { FC } from "react";
import logo from "../assets/LogoTEALCA.svg";
import { useParams } from "react-router-dom";

const NotFound: FC = () => {
  const { shipmentNotFound } = useParams();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img src={logo} alt="Logo TEALCA" className="h-96 mb-10 px-4" />

      <h1 className="text-4xl text-center font-bold text-gray-800 px-4">
        {" "}
        {shipmentNotFound ? "Guía no encontrada" : "Página no encontrada"}{" "}
      </h1>
      <br />

      <p className="text-lg text-center text-gray-600 px-4">
        {shipmentNotFound
          ? "Lo sentimos, la guía que está solicitando no fue encontrada. Por favor, verifique el ID de la guía e inténtelo nuevamente."
          : "Lo sentimos, la página que está buscando no existe. Por favor, verifique la URL e inténtelo nuevamente."}
      </p>
    </div>
  );
};

export default NotFound;
